<template>
  <base-section id="k-d-a-tech-detail-our-project" top-inner-shadow>
    <v-container>
      <v-row no-gutters>
        <v-col>
          <component
            :is="m_objTitle.strComponent"
            :class="`kda-ts-${g_bLowerBr ? 24 : 46}pt wt-extrabold ln-150 text-center`"
            v-html="m_objTitle.htmlText"
          />
        </v-col>
      </v-row>

      <v-row no-gutters justify="center" class="pt-10 px-4" style="gap: 56px">
        <template v-if="!g_bLowestBr">
          <v-col v-for="(item, id) in currentTechnology" :key="id" cols="auto">
            <v-card
              max-width="326"
              elevation="3"
              height="100%"
              tile
              color="white"
              class="cursor-pointer"
              @click="navToDetailProject(item.strLink)"
            >
              <p class="kda-ts-16pt wt-bold nunito main-bw-400--text px-3 pt-4 pb-3">
                {{ item.strType }}
              </p>

              <base-img :src="item.imgSrc" cover style="max-height: 280px" class="py-4" />

              <div class="px-8 py-4">
                <h4 class="kda-ts-28pt wt-extrabold mb-4">{{ item.strTitle }}</h4>

                <component
                  :is="item.strComponent"
                  class="kda-ts-16pt ln-150 nunito main-bw-300--text"
                  v-html="item.strDesc"
                />
              </div>
            </v-card>
          </v-col>
        </template>

        <v-col v-else>
          <v-card flat tile>
            <v-window v-model="iProjectIndex" class="mb-2">
              <v-window-item v-for="n in currentTechnology.length" :key="`card-${n}`">
                <v-card
                  color="grey"
                  height="280"
                  width="326"
                  class="mx-auto mb-2"
                  elevation="4"
                  @click="navToDetailProject(currentTechnology[iProjectIndex].strLink)"
                >
                  <base-img :src="currentTechnology[iProjectIndex].imgSrc" class="fill-height">
                    <div
                      class="fill-height full-width d-flex align-end"
                      style="background-image: linear-gradient(#00000000, #000000b4) !important"
                    >
                      <h1 class="white--text kda-ts-28pt wt-extrabold pa-4">
                        {{ currentTechnology[iProjectIndex].strTitle }}
                      </h1>
                    </div>
                  </base-img>
                </v-card>
              </v-window-item>
            </v-window>

            <v-card-actions class="justify-space-between">
              <v-item-group v-model="iProjectIndex" class="text-center mx-auto my-2" mandatory>
                <v-item
                  v-for="n in currentTechnology.length"
                  :key="`window-btn-slider-${n}`"
                  v-slot="{ active, toggle }"
                >
                  <v-btn :input-value="active" icon x-small @click="toggle">
                    <v-icon small>mdi-record</v-icon>
                  </v-btn>
                </v-item>
              </v-item-group>
            </v-card-actions>
          </v-card>

          <div v-if="currentTechnology.length > 3" class="d-flex justify-center align-center">
            <v-btn icon elevation="1" class="main-bw-300 mr-4">
              <v-icon color="white">mdi-arrow-right</v-icon>
            </v-btn>

            <p class="kda-ts-16pt wt-semibold nunito">{{ m_strButtonMore }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDATechDetailOurProject',

  mixins: [BaseBreakpoint],

  data: () => ({
    iProjectIndex: 0
  }),

  computed: {
    m_strButtonMore() {
      return 'Lihat Lebih Banyak Proyek';
    },
    m_objTitle() {
      return {
        htmlText: this.currentTitle,
        strComponent: 'h1'
      };
    },
    arrTechnology() {
      const projectsLink = ['wayfinder', 'axa', 'load-container'];

      return {
        'unreal-engine': [
          {
            strType: this.$vuetify.lang.t('$vuetify.kda.technology.unreal-engine.project.title'),
            strTitle: this.$vuetify.lang.t('$vuetify.kda.technology.unreal-engine.project.name'),
            strDesc: this.$vuetify.lang.t('$vuetify.kda.technology.unreal-engine.project.desc'),
            strComponent: 'p',
            strLink: projectsLink[1],
            imgSrc: require('@/assets/photos/home-project-2.png')
          },
          {
            strType: '-',
            strTitle: '-',
            strDesc: '-',
            imgSrc: require('@/assets/photos/tech-detail-empty.png')
          },
          {
            strType: '-',
            strTitle: '-',
            strDesc: '-',
            imgSrc: require('@/assets/photos/tech-detail-empty.png')
          }
        ],
        'my-sql': [
          {
            strType: this.$vuetify.lang.t('$vuetify.kda.technology.my-sql.project.title'),
            strTitle: this.$vuetify.lang.t('$vuetify.kda.technology.my-sql.project.name'),
            strDesc: this.$vuetify.lang.t('$vuetify.kda.technology.my-sql.project.desc'),
            strComponent: 'p',
            strLink: projectsLink[0],
            imgSrc: require('@/assets/photos/home-project-1.png')
          },
          {
            strType: '-',
            strTitle: '-',
            strDesc: '-',
            imgSrc: require('@/assets/photos/tech-detail-empty.png')
          },
          {
            strType: '-',
            strTitle: '-',
            strDesc: '-',
            imgSrc: require('@/assets/photos/tech-detail-empty.png')
          }
        ],
        'django-python': [
          {
            strType: this.$vuetify.lang.t('$vuetify.kda.technology.django-python.project.title'),
            strTitle: this.$vuetify.lang.t('$vuetify.kda.technology.django-python.project.name'),
            strDesc: this.$vuetify.lang.t('$vuetify.kda.technology.django-python.project.desc'),
            strComponent: 'p',
            strLink: projectsLink[2],
            imgSrc: require('@/assets/photos/home-project-3.png')
          },
          {
            strType: '-',
            strTitle: '-',
            strDesc: '-',
            imgSrc: require('@/assets/photos/tech-detail-empty.png')
          },
          {
            strType: '-',
            strTitle: '-',
            strDesc: '-',
            imgSrc: require('@/assets/photos/tech-detail-empty.png')
          }
        ],
        unity: [
          {
            strType: '-',
            strTitle: '-',
            strDesc: '-',
            imgSrc: require('@/assets/photos/tech-detail-empty.png')
          },
          {
            strType: '-',
            strTitle: '-',
            strDesc: '-',
            imgSrc: require('@/assets/photos/tech-detail-empty.png')
          },
          {
            strType: '-',
            strTitle: '-',
            strDesc: '-',
            imgSrc: require('@/assets/photos/tech-detail-empty.png')
          }
        ],
        'keda-engine': [
          {
            strType: this.$vuetify.lang.t(
              '$vuetify.kda.technology.keda-engine.project.item1.title'
            ),
            strTitle: this.$vuetify.lang.t(
              '$vuetify.kda.technology.keda-engine.project.item1.name'
            ),
            strDesc: this.$vuetify.lang.t('$vuetify.kda.technology.keda-engine.project.item1.desc'),
            strComponent: 'p',
            strLink: projectsLink[0],
            imgSrc: require('@/assets/photos/home-project-1.png')
          },
          {
            strType: this.$vuetify.lang.t(
              '$vuetify.kda.technology.keda-engine.project.item2.title'
            ),
            strTitle: this.$vuetify.lang.t(
              '$vuetify.kda.technology.keda-engine.project.item2.name'
            ),
            strDesc: this.$vuetify.lang.t('$vuetify.kda.technology.keda-engine.project.item2.desc'),
            strComponent: 'p',
            imgSrc: require('@/assets/photos/tech-detail-empty.png')
          },
          {
            strType: this.$vuetify.lang.t(
              '$vuetify.kda.technology.keda-engine.project.item3.title'
            ),
            strTitle: this.$vuetify.lang.t(
              '$vuetify.kda.technology.keda-engine.project.item3.name'
            ),
            strDesc: this.$vuetify.lang.t('$vuetify.kda.technology.keda-engine.project.item3.desc'),
            strComponent: 'p',
            strLink: projectsLink[2],
            imgSrc: require('@/assets/photos/home-project-3.png')
          }
        ]
      };
    },
    currentTechnology() {
      const currentTechnology = Object.keys(this.arrTechnology).filter(
        (item) => item === this.$route.params.strTechnology
      );

      return this.arrTechnology[currentTechnology];
    },
    currentTitle() {
      const paramsTitle = this.$route.params.strTechnology;

      let title = '';

      if (paramsTitle === 'unreal-engine') {
        title = this.$vuetify.lang.t('$vuetify.kda.technology.unreal-engine.project.use');
      } else if (paramsTitle === 'my-sql') {
        title = this.$vuetify.lang.t('$vuetify.kda.technology.my-sql.project.use');
      } else if (paramsTitle === 'django-python') {
        title = this.$vuetify.lang.t('$vuetify.kda.technology.django-python.project.use');
      } else if (paramsTitle === 'unity') {
        title = this.$vuetify.lang.t('$vuetify.kda.technology.unity.project.use');
      } else if (paramsTitle === 'keda-engine') {
        title = this.$vuetify.lang.t('$vuetify.kda.technology.keda-engine.project.use');
      }

      return title;
    }
  },

  methods: {
    navBack() {
      this.$router.back();
    },
    navToDetailProject(link) {
      if (link) {
        this.$router.push(`/project/detail/${link}`);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.col-reverse {
  display: flex !important;
  flex-direction: column-reverse !important;
}

.row-wrapper {
  display: flex;
  flex-direction: row;
}

.background-tech-list {
  background-color: #fbfbfb;
}

.cursor-pointer {
  cursor: pointer;
}

.padding {
  padding: 0px !important;
}

.img-container {
  overflow: hidden;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  img {
    &.right {
      height: 460px;
      width: 460px;
    }

    &.top {
      height: fit-content;
      width: 290px;
      position: relative;
      margin-top: 50px;
    }
  }
}
</style>
